//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";
@import "./core/layout/docs/layout";

//Prime react
@import "primereact/resources/themes/lara-light-indigo/theme.css"; 
@import "primereact/resources/primereact.min.css";                  
@import "primeicons/primeicons.css";   