#floatingTextarea2 {
    resize: none!important;
}

#floatingTextarea3 {
    resize: none!important;
}

.platformTaskHistoryOverview {
    .row {
        padding: 1rem 0;
    }
}