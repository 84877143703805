.companyOverviewPage {
    .vp-loader-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(100vh - 200px);
    }
    .vismaEAHeaderAndConfigure {
        display: flex;
        justify-content: space-between;
    }
    .eachDetail {
        padding: 1rem 0;
    }
    .eachConfig {
        padding: 1rem 0;
    }
}