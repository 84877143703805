.companyConfigDetailsPage {
    .sectionHeader {
        margin-bottom: 1.25rem;
        margin-top: 2rem;
        &:first-child{
            margin-top: 0px;
        }
    }
    .vwp-config-group {
        margin-bottom: 2rem;
        .vwp-each-config {
            padding-left: 1rem;
        }
    }

}