.new-tenant-modal{
    max-width: 700px;
    .modal-header {
        > h2{
            font-weight: 500;
        }
        > .status-block {
            label{
                font-weight: 500;
                font-size: 15px;
                display: flex;
                align-items: center;
                > .badge {
                    font-size: 13px;
                    padding: 5px 6px;
                    margin-left: 8px
                }
            }
            .refresh-btn{
                padding: calc(0.6rem + 1px) !important;
                &.rotate-clockwise {
                    animation: rotation 2s infinite linear;
                }
                span.svg-reload {
                    margin-right: 0;
                    path {
                        fill: #1DC894
                    }
                }
            }

            .success{
                svg{
                    width: 13px !important;
                    height: 13px !important;
                }
                path {
                    fill: #2AE4AC;
                }
            }
            .pending{
                svg{
                    width: 13px !important;
                    height: 13px !important;
                }
                path {
                    fill: #00A3FF; 
                }
            }
            .failed{
                svg{
                    width: 13px !important;
                    height: 13px !important;
                }
                path {
                    fill: #FF4D4F;
                }
            }
        }
        .btn-close {
            margin: 0 !important
        }
    }
    .modal-body {
        form {
            .form-group {
                > label {
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }
}

img.w18{
    max-width: 18px;
    max-height: 18px;
    object-fit: contain;
}

.modal:has( > .error-toast-modal ),
.modal:has( > .success-toast-modal ) {
    right: 25px;
    left: unset;
    top: 0;
    width: 325px;
}

.error-toast-modal, .success-toast-modal {
    .modal-header {
        display: none;
    }
    .modal-body {
        display: flex;
        justify-content: space-between;
        padding: 1.2rem;
        > .leftDiv {
            display: flex;
            > div {
                margin: 0 10px;
                > h2 {
                    font-size: 16px;
                }
            }
        }
    }
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }