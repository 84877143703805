.companyTable {
    .p-datatable-header {
        padding: 0rem !important;
    }

    /* .p-datatable .p-datatable-tbody>tr>td {
      text-align: left;
      border: rgb(214, 212, 212) !important;
      border-width: 0 0 1px !important;
      padding: 1rem;
  } */

    .customer-badge {
        border-radius: 5px !important;
    }

    .customer-badge.status-Success {
        background-color: #c8e6c9;
        color: #256029;
    }

    .customer-badge.status-Pending {
        background-color: #b3e5fc;
        color: #23547b;
    }

    .customer-badge.status-Failed {
        background-color: #ffcdd2;
        color: #c63737;
    }

    .customer-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;
    }

    .p-datatable .p-datatable-header {
        border: none !important;
    }

    .p-datatable .p-datatable-footer,
    .p-datatable .p-datatable-thead > tr > th {
        background: #ffffff !important;
        border-bottom: 1px #eff2f5 solid !important;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        border-bottom: 1px #eff2f5 solid !important;
        font-weight: 600 !important;
        font-size: 1.075rem !important;
    }

    .p-datatable .p-paginator-bottom,
    .p-datatable .p-paginator-top {
        border-width: 0 0 0px !important;
        border-bottom-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    .p-paginator {
        justify-content: unset;
        display: block !important;
        padding: 2rem 2rem 2rem 2rem !important;
    }

    .card-header {
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px !important;
        padding: 2rem 2rem 2rem 2rem !important;
    }

    .p-column-title,
    .p-sortable-column-icon {
        color: #adb5bd !important;
    }

    .p-column-title {
        text-transform: uppercase;
    }

    .p-datatable-tbody > tr > td {
        font-weight: 700;
    }
    .row {
        padding: 2rem 1rem 2rem 1rem !important;
    }

    .p-dropdown {
        float: right !important;
        text-align: right !important;
    }

    .p-component,
    .p-inputtext {
        font-family: unset !important;
    }

    .p-inputtext {
        font-family: unset !important;
    }

    .p-column-filter {
        width: 90%;
        text-align: left !important;
    }

    .p-progress-spinner {
        display: block !important;
    }

    /* modal content */
    .field-name {
        font-size: 16px;
    }

    .field-value {
        font-weight: bold;
        font-size: 16px;
    }

    .pr_id_2_header {
        font-size: 18px !important;
    }

    .field-div {
        text-align: right !important;
    }
}
