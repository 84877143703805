.updatePlatformTaskModal {
  .stepper-check.disabled-grey {
    filter: grayscale(1);
    opacity: 0.3;
  }
  .stepper-number.disabled-grey {
    filter: grayscale(1);
    opacity: 0.3;
  }
  .vp-field-schedule-time {
    display: flex;
    > div:first-child {
      min-width: 200px;
    }
  }
  .vp-timepicker-popper {
    padding: 0 !important;
    .react-datepicker__time {
      font-size: 12px;
      font-family: Poppins;
    }
  }
}
