.view-tenant-modal{
    max-width: 800px;
    .modal-header {
        > h2{
            font-weight: 500;
        }
        > .status-block {
            label{
                font-weight: 500;
                font-size: 15px;
                display: flex;
                align-items: center;
                > .badge {
                    font-size: 13px;
                    padding: 5px 6px;
                    margin-left: 8px
                }
            }
            .svg-reload {
                path {
                    fill: #1DC894
                }
            }
            .success{
                svg{
                    width: 13px !important;
                    height: 13px !important;
                }
                path {
                    fill: #2AE4AC;
                }
            }
            .pending{
                svg{
                    width: 13px !important;
                    height: 13px !important;
                }
                path {
                    fill: #00A3FF; 
                }
            }
            .failed{
                svg{
                    width: 13px !important;
                    height: 13px !important;
                }
                path {
                    fill: #FF4D4F;
                }
            }
        }
        .btn-close {
            margin: 0 !important
        }
    }
    .modal-body {
        form {
            .flex-side{
                width: calc(50% - 40px);
                .form-group {
                    > label {
                        font-weight: 500;
                        font-size: 14px;
                    }
                    // &:last-child{
                    //     width: 180px;
                    // }
                }
            }
        }
        .spinner-overlay {
            background: #9e9e9ed9;
            position: absolute;
            width: calc(100% - 3.5rem);
            height: calc(100% - 3.5rem);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}