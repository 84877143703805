.edit-tenant-modal{
    max-width: 600px;
    .modal-header {
        > h2{
            font-weight: 500;
        }
        > .status-block {
            label{
                font-weight: 500;
                font-size: 15px;
                display: flex;
                align-items: center;
                > .badge {
                    font-size: 13px;
                    padding: 5px 6px;
                    margin-left: 8px
                }
            }
            .refresh-btn{
                padding: calc(0.6rem + 1px) !important;
                &.rotate-clockwise {
                    animation: rotation 2s infinite linear;
                }
                span.svg-reload {
                    margin-right: 0;
                    path {
                        fill: #1DC894
                    }
                }
            }
            .success{
                svg{
                    width: 13px !important;
                    height: 13px !important;
                }
                path {
                    fill: #2AE4AC;
                }
            }
            .pending{
                svg{
                    width: 13px !important;
                    height: 13px !important;
                }
                path {
                    fill: #00A3FF; 
                }
            }
            .failed{
                svg{
                    width: 13px !important;
                    height: 13px !important;
                }
                path {
                    fill: #FF4D4F;
                }
            }
        }
        .btn-close {
            margin: 0 !important
        }
    }
    .modal-body {
        form {
            .form-group {
                > label {
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }
}