.modal-body {
    tags {
        min-width: 100%;
    }

    .row {
        padding: 1rem 1rem !important;
    }

    .modal-loader-container {
        position: relative;
    }

    .overlay-layer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* Add other styling properties as needed */
    }
}